// extracted by mini-css-extract-plugin
export var container = "PrivacyPolicy-module--container--1yWHp";
export var content = "PrivacyPolicy-module--content--3yGxF";
export var title = "PrivacyPolicy-module--title--2OrZJ";
export var contentSection = "PrivacyPolicy-module--contentSection--1oc1z";
export var subtitle = "PrivacyPolicy-module--subtitle--3pSCW";
export var text = "PrivacyPolicy-module--text--RurtX";
export var text2 = "PrivacyPolicy-module--text2--3Ulh6";
export var otherLinks = "PrivacyPolicy-module--otherLinks--1tM-D";
export var textEmail = "PrivacyPolicy-module--textEmail--38y3D";
export var buttonToTop = "PrivacyPolicy-module--buttonToTop--1i9Ja";
export var buttonToTopHidden = "PrivacyPolicy-module--buttonToTopHidden--1GP0T";
export var footerContainer = "PrivacyPolicy-module--footerContainer--3-0Hm";